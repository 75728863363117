import NIconButton from "../buttons/n-icon-button.js";

const EVENTS = {

}

const MOCK_FIELD_STATES = {
    HAS_COMMENT: 0,
    IS_APPROVED: 0,
    HAS_NON_CONFORMITY: 0,
}


export default {
    components: {
        "n-icon-button": NIconButton
    },
    props: {
        fieldState: Object,
        fieldResult: {
            type: Number,
            default: FIELD_CONTROL_POINT_RESULT.NOT_SET
        },
        field: String,
        disableComment: Boolean,
        align: String,
        column: Boolean,
    },
    data() {
        return {
            inputElement: null,
            state: {
                isApproved: false,
                hasComment: false,
                hasNonConformity: false,
            }
        }
    },
    emits: Object.values(EVENTS),
    methods: {

        initFieldState() {
            // todo: Handle some kind of initiation of buttons depending on field state
            const {comment, state} = this.fieldState

            if (comment) this.state.hasComment = true
            if (state === MOCK_FIELD_STATES.IS_APPROVED) this.state.isApproved = true
            if (state === MOCK_FIELD_STATES.HAS_NON_CONFORMITY) this.state.hasNonConformity = true
        },
        getAlignment() {

            if (this.align === 'center') return ' g-align-center'
            if (this.align === 'end') return ' g-align-end'
            if (this.align === 'start') return ' g-align-start'

        }
    },
    created() {
        if (this.fieldResult === FIELD_CONTROL_POINT_RESULT.APPROVED) {
            this.state.isApproved = true
        } else if (this.fieldResult === FIELD_CONTROL_POINT_RESULT.REJECTED
            || this.fieldResult === FIELD_CONTROL_POINT_RESULT.RESOLVED) {
            this.state.hasNonConformity = true
        }
    },
    mounted() {
    },
    template: `
        <div class="n-flex review-options" :class="getAlignment()">
            <span style="width: 100%">
                <slot name="input"></slot>
            </span>
        </div>

        `,
}

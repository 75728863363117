import NReviewOptions from "./n-review-options.js";
import NReviewOptionsDisabled from "./n-review-options-disabled.js";
import WizardCommentBlock from "./wizard-comment-block.js";
import NWizardFormBlock from "./wizard-form-block.js";
import NonConformityItem from "./non-conformity-item.js";
import NonConformityItemDisabled from "./non-conformity-item-disabled.js";

export default {
    "n-review-options": NReviewOptions,
    "n-review-options-disabled": NReviewOptionsDisabled,
    "wizard-comment-block": WizardCommentBlock,
    "wizard-form-block": NWizardFormBlock,
    "non-conformity-item": NonConformityItem,
    "non-conformity-item-disabled": NonConformityItemDisabled
}

